
import { defineComponent } from 'vue';
import { useToast } from "vue-toastification";

import AppButton from '@/components/stateless/AppButton.vue';

export default defineComponent({
  name: 'Notifications',

  components: { AppButton },

  setup() {
    const toast = useToast();

    function showInfo() {
      toast('Info toast');
    }

    function showSuccess() {
      toast.success('Success toast');
    }

    function showError() {
      toast.error('Error toast');
    }

    return { showSuccess, showInfo, showError };
  }
});
