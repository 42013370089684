<template>
  <div class="text-primary text-3xl text-center p-7 border-b border-grey-fp-30">Notificaions</div>
  <div class="flex justify-center items-center h-full">
    <AppButton type="success" size="small" class="mr-10 px-20" @click="showSuccess"> Success </AppButton>
    <AppButton type="error" size="small" class="mr-10 px-20" @click="showError"> Error </AppButton>
    <AppButton type="primary" size="small" class="mr-10 px-20" @click="showInfo"> Info </AppButton>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useToast } from "vue-toastification";

import AppButton from '@/components/stateless/AppButton.vue';

export default defineComponent({
  name: 'Notifications',

  components: { AppButton },

  setup() {
    const toast = useToast();

    function showInfo() {
      toast('Info toast');
    }

    function showSuccess() {
      toast.success('Success toast');
    }

    function showError() {
      toast.error('Error toast');
    }

    return { showSuccess, showInfo, showError };
  }
});
</script>